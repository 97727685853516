import React, { useState } from 'react';
import { Button } from './Button';

import './Hand.css';

export const Hand = ({categories, clueWords, placeWords}) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [selectedWords, setSelectedWords] = useState(new Set());
  const [highlightedWords, setHighlightedWords] = useState(new Set());

  const clueWordClick = (clueWord) => {
    let newSelectedWords = new Set(selectedWords);
    if (selectedWords.has(clueWord)) {
      newSelectedWords.delete(clueWord);
    } else {
      newSelectedWords.add(clueWord);
    }
    setSelectedWords(newSelectedWords);
  }

  const placeClick = () => {
    placeWords(selectedWords);
    selectedWords.forEach((selectedWord) => {highlightedWords.delete(selectedWord)});
    setHighlightedWords(new Set(highlightedWords));
    setSelectedWords(new Set());
  }

  const highlightLabel = selectedWords.size > 0 && [...selectedWords].every(word => highlightedWords.has(word))
    ? "Un-Highlight"
    : "Highlight";
  const highlightClick = () => {
    if ([...selectedWords].every(word => highlightedWords.has(word))) {
      // remove selectedWords from highlightedWords
      selectedWords.forEach((selectedWord) => {highlightedWords.delete(selectedWord)});
      setHighlightedWords(new Set(highlightedWords));
    } else {
      // add selectedWords to highlightedWords
      selectedWords.forEach((selectedWord) => {highlightedWords.add(selectedWord)});
      setHighlightedWords(new Set(highlightedWords));
    }
    setSelectedWords(new Set());
  }

  return (
    <div className="Hand">
      <div className="HandCardsContainer">
        {clueWords[selectedCategory].sort().map(clueWord =>
          <Button
            className="HandCard"
            label={clueWord}
            handleClick={() => {clueWordClick(clueWord)}}
            selected={selectedWords.has(clueWord)}
            highlighted={highlightedWords.has(clueWord)}
            key={clueWord}
          />
        )}
      </div>
      <div className="HandActionsContainer">
        <Button
          label={'Place'}
          handleClick={() => {placeClick()}}
          selected={false}
          enabled={selectedWords.size !== 0}
        />
        <Button
          label={highlightLabel}
          handleClick={() => {highlightClick()}}
          selected={false}
          enabled={selectedWords.size !== 0}
        />
        {/* ToDo: implement flip cards  <Button
          label={'Flip'}
          handleClick={() => {flipClick()}}
          selected={false}
          key={'Flip'}
        />*/}
      </div>
      <div className="HandCategoriesContainer">
        {categories.map(category =>
          <Button
            label={category}
            handleClick={() => {
              setSelectedCategory(category);
            }}
            selected={selectedCategory === category}
            key={category}
          />
        )}
      </div>
    </div>
  );
}

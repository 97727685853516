import React from 'react';

import './RoomOverlay.css';

export const RoomOverlay = ({roomInfo, username}) => {
  return (
    <div className="RoomOverlay">
      <div className="RoomOverlayTitle">Room code:</div>
      <div className="RoomOverlayCode">{roomInfo.roomcode}</div>
      <div className="RoomPlayers">
        Players:
        {roomInfo.players.map((player, i) => 
          <p className={player === username ? 'CurrentPlayer' : ''} key={'' + i + '-' + player}>
            {player}
          </p>)}
      </div>
    </div>
  );
}

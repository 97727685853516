import React, { useState, useEffect } from 'react';
import { Chat } from './Chat';
import { Hand } from './Hand';
import { Field } from './Field';
import { MainMenu } from './MainMenu';
import { RoomOverlay } from './RoomOverlay';
import { GameEndOverlay } from './GameEndOverlay';

import './App.css';

function App() {
  const defaultAppPage = 'mainmenu';
  const defaultRoomInfo = {'roomcode': null, 'haswon': false, 'wonword': null, 'players': []};
  const [appPage, setAppPage] = useState(defaultAppPage); // 'mainmenu', 'cluegiver', 'guesser'
  const [roomInfo, setRoomInfo] = useState(defaultRoomInfo);
  const [username, setUsername] = useState();
  const [guesses, setGuesses] = useState([]);
  const [pendingGuesses, setPendingGuesses] = useState([]);
  const [categories, setCategories] = useState();
  const [clueWords, setClueWords] = useState();
  const [targetWord, setTargetWord] = useState();
  const [fieldCards, setFieldCards] = useState();
  const [cardsPosGuesser, setCardsPosGuesser] = useState([]);
  // Guesses = ['old guess', 'guess 2', 'other one', 'b', 'c', 'd', 'e'];
  // Categories = ['n', 'a', 'v', 'p'];
  // ClueWords = {'n' : ['dog', 'man', 'apple', 'sport', 'feeling'], 'v' : ['run', 'eat'], 'p' :['to'], 'a' : ['good', 'free']};
  // Target = 'wonder';
  // FieldCards = [{'category': 'n', 'word':'dog'}];
  // GuesserCardsPos = [{'word': 'dog', 'x': 0.12, 'y': 0.79}];
  // RoomInfo = {'roomcode': 'ABCD', 'haswon': false, 'wonword': null, 'players': ['Bob', 'Charlie']};

  const updateFromServer = () => {
    if (roomInfo === null || !(appPage === 'cluegiver' || appPage === 'guesser')) {
      return;
    }
    const requestStartTime = Date.now();
    fetch("https://wordgame.vinstuff.com/queryroom.php?roomcode=" + roomInfo.roomcode)
      .then(response => response.json())
      .then(
        (result) => {
          // To-do: if a guess is submitted while updateFromServer is pending, it can be shown in both guesses and pendingGuesses
          const newPendingGuesses = pendingGuesses.filter((pendingGuess) => pendingGuess.time > requestStartTime);
          if (JSON.stringify(newPendingGuesses) !== JSON.stringify(pendingGuesses)) {
            setPendingGuesses(newPendingGuesses);
          }
          if (JSON.stringify(result.guesses) !== JSON.stringify(guesses)) {
            setGuesses(result.guesses);
          }
          if (JSON.stringify(result.roomInfo) !== JSON.stringify(roomInfo)) {
            setRoomInfo(result.roomInfo);
          }
          if (appPage === 'cluegiver') {
          } else if (appPage === 'guesser') {
            if (JSON.stringify(result.fieldCards) !== JSON.stringify(fieldCards)) {
              setFieldCards(result.fieldCards);
            }
            if (JSON.stringify(result.cardsPos) !== JSON.stringify(cardsPosGuesser)) {
              setCardsPosGuesser(result.cardsPos);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  useEffect(() => {
    // To-do: set some timeout thing so if the player idles I don't spam my server
    const serverPollIntervalID = setInterval(updateFromServer, 1000);
    return function cancelTimer() {
      clearInterval(serverPollIntervalID);
    }
  });

  const createGame = (newUsername) => {
    // To-do: create loading spinner thing
    fetch("https://wordgame.vinstuff.com/createroom.php?username=" + newUsername)
      .then(response => response.json())
      .then(
        (result) => {
          setUsername(newUsername);
          setFieldCards([]);
          setRoomInfo(result.roomInfo);
          setCategories(result.categories);
          setClueWords(result.clueWords);
          setTargetWord(result.targetWord);
          setGuesses([]);
          setAppPage('cluegiver');
        },
        (error) => {
          console.log(error);
          window.alert("Error creating game");
        }
      );
  }

  const joinGame = (code, newUsername) => {
    // To-do: create loading spinner thing
    fetch("https://wordgame.vinstuff.com/joinroom.php?roomcode=" + code + "&username=" + newUsername)
      .then(response => response.json())
      .then(
        (result) => {
          setUsername(newUsername);
          setRoomInfo(result.roomInfo);
          setGuesses(result.guesses);
          setFieldCards(result.fieldCards);
          setCardsPosGuesser(result.cardsPos);
          setAppPage('guesser');
        },
        (error) => {
          window.alert("Error joining game");
        }
      );
  }

  const updateServerForCurrentRoom = (stateKey, stateValue) => {
    fetch("https://wordgame.vinstuff.com/updateroom.php?roomcode=" + roomInfo.roomcode + "&statekey=" + stateKey + "&statevalue=" + JSON.stringify(stateValue))
      .then(
        (response) => {},
        (error) => {
          console.log("Error sending update to server");
        }
      );
  }

  const resetToMainMenu = () => {
    setAppPage('mainmenu');
    setRoomInfo(defaultRoomInfo);
    // TODO: instead of returning to the main menu, set the current user as the cluegiver (so the players can keep reusing the room)
    // (like `createGame`, but make sure multiple people can't race; oh, can send 'wonword' to see if cluegiver has been claimed yet)
  }

  const recallCards = (cards) => {
    cards.forEach((card) => {
      fieldCards.splice(fieldCards.indexOf(card), 1);
      clueWords[card.category].push(card.word);
    });
    setFieldCards([...fieldCards]);
    setClueWords({...clueWords});
    updateServerForCurrentRoom('fieldCards', fieldCards);
  }

  const movedCards = (cardsPos) => {
    updateServerForCurrentRoom('cardsPos', cardsPos);
  }

  const placeWords = (words) => {
    words.forEach((word) => {
      for (const category in clueWords) {
        if (clueWords[category].includes(word)) {
          fieldCards.push({'category': category, 'word': word});
          clueWords[category].splice(clueWords[category].indexOf(word), 1);
        }
      }
    });
    setFieldCards([...fieldCards]);
    setClueWords({...clueWords});
    updateServerForCurrentRoom('fieldCards', fieldCards);
  }

  const submitGuess = (guessStr) => {
    const guessObj = {'username': username, 'guessStr': guessStr};
    pendingGuesses.push({'guessStr': guessStr, 'time': Date.now()});
    setPendingGuesses([...pendingGuesses]);
    updateServerForCurrentRoom('guess', guessObj);
  }

  let body = <div />;
  if (appPage === "mainmenu") {
    body =
      <MainMenu
        onClickNewGame={createGame}
        onJoinGame={joinGame}
        username={username}
      />;
  } else if (appPage === "cluegiver" || appPage === "guesser") {
    body = <>
      <Field
        cards={fieldCards}
        cardsPosGuesser={appPage === 'guesser' ? cardsPosGuesser : []}
        recallCards={recallCards}
        movedCards={movedCards}
        isCluegiver={appPage === 'cluegiver'}
      />
      <Chat
        guesses={guesses}
        pendingGuesses={pendingGuesses}
        username={username}
        target={appPage === 'cluegiver' ? targetWord : null}
        isCluegiver={appPage === 'cluegiver'}
        submitGuess={submitGuess}
      />
      {appPage === 'cluegiver'
        ? <Hand
            categories={categories}
            clueWords={clueWords}
            placeWords={placeWords}
          />
        : null}
    </>;
  }
  return (
    <div className={"App " + appPage}>
      {roomInfo.roomcode ? <RoomOverlay roomInfo={roomInfo} username={username} /> : null}
      {roomInfo.haswon ? <GameEndOverlay roomInfo={roomInfo} resetToMainMenu={resetToMainMenu} /> : null}
      {body}
    </div>
  );
}

export default App;
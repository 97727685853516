import React, { useState } from 'react';
import { Button } from './Button';

import './Field.css';

export const Field = ({cards, cardsPosGuesser, recallCards, movedCards, isCluegiver}) => {
  const [selectedCards, setSelectedCards] = useState([]); // [{category: 'n', word: 'dog'}, ...]
  const [cardsPosCluegiver, setCardsPosCluegiver] = useState([]); // [{word: 'dog', x: 0.12, y: 0.79}, ...]
  const [isDragging, setIsDragging] = useState(false);

  // If isCluegiver, use state's cardsPosCluegiver; else use prop's cardsPosGuesser
  const getCardsPos = () => {
    return isCluegiver ? cardsPosCluegiver : cardsPosGuesser;
  }
  const getCardsPosI = (word) => {
    return getCardsPos().findIndex((cardPos) => cardPos.word === word);
  }

  const recallClick = () => {
    recallCards(selectedCards);
    selectedCards.forEach((card) => {
      const cardsPosI = getCardsPosI(card.word);
      if (cardsPosI !== -1) {
        cardsPosCluegiver.splice(cardsPosI, 1);
      }
    });
    movedCards(cardsPosCluegiver);
    setSelectedCards([]);
  }
  const cardClick = (e, card) => {
    if (isDragging) {
      setIsDragging(false);
      return;
    }
    if (selectedCards.includes(card)) {
      selectedCards.splice(selectedCards.indexOf(card), 1);
    } else {
      selectedCards.push(card);
    }
    setSelectedCards([...selectedCards]);
  }

  const getFieldCard = (word) => {
    return document.querySelector(".FieldCard[data-card-word='" + word + "']");
  }

  const getCardStyle = (card) => {
    const cardsPosI = getCardsPosI(card.word);
    if (cardsPosI !== -1) {
      // shift so that the middle of the whole card is at the cardPos 
      const cardElement = getFieldCard(card.word);
      const offsetX = cardElement ? cardElement.offsetWidth / 2 : 0;
      const offsetY = cardElement ? cardElement.offsetHeight / 2 : 0;
      return {left: 'calc(' + (getCardsPos()[cardsPosI]['x'] * 100) + '% - ' + offsetX + 'px)',
              top: 'calc(' + (getCardsPos()[cardsPosI]['y'] * 100) + '% - ' + offsetY + 'px)',
              zIndex: cardsPosI + 1}
    } else {
      return {left: 'calc(' + (3 + (11 * [...cards].sort().indexOf(card))) % 100 + '% - 0px)',
              bottom: '5%',
              zIndex: 0};
    }
  }

  const startDrag = (e, card) => {
    setIsDragging(false);
    // figure out the cursor offset relative to the top middle of the card
    const boundingRect = getFieldCard(card.word).getBoundingClientRect();
    const cursorPos = getEventCursorPos(e);
    const xOffset = cursorPos.x - (boundingRect.left + window.scrollX + (boundingRect.width / 2));
    const yOffset = cursorPos.y - (boundingRect.top + window.scrollY + (boundingRect.height / 2));
    const offsetObj = {x: xOffset, y: yOffset};
    document.onmouseup = (e) => dragEnd(e, card, offsetObj);
    document.ontouchend = (e) => dragEnd(e, card, offsetObj);
    document.onmousemove = (e) => dragMove(e, card, offsetObj);
    document.ontouchmove = (e) => dragMove(e, card, offsetObj);
  }

  const dragMove = (e, card, offsetObj) => {
    updateDragPos(e, card, offsetObj);
    setIsDragging(true);
  }

  const dragEnd = (e, card, offsetObj) => {
    updateDragPos(e, card, offsetObj);
    document.onmouseup = null;
    document.ontouchend = null;
    document.onmousemove = null;
    document.ontouchmove = null;
    movedCards(cardsPosCluegiver);
  }

  const getEventCursorPos = (e) => {
    let cursorX;
    let cursorY;
    if (e.touches || e.changedTouches) {
      cursorX = e.touches[0] ? e.touches[0].pageX : e.changedTouches[0].pageX;
      cursorY = e.touches[0] ? e.touches[0].pageY : e.changedTouches[0].pageY;
    } else if (e.pageX !== undefined) {
      cursorX = e.pageX;
      cursorY = e.pageY;
    } else {
      return null;
    }
    return {x: cursorX, y: cursorY};
  }

  const updateDragPos = (e, card, offsetObj) => {
    // Optional to-do: move all selectedCards instead of just this one (and figure out boundaries stuff)
    const boundingRect = document.getElementById('FieldCardsContainer').getBoundingClientRect();
    const cursorPos = getEventCursorPos(e);
    const cursorShiftedPageX = cursorPos.x - offsetObj.x;
    const cursorShiftedPageY = cursorPos.y - offsetObj.y;
    let xPercent = (cursorShiftedPageX - (boundingRect.left + window.scrollX)) / boundingRect.width;
    let yPercent = (cursorShiftedPageY - (boundingRect.top + window.scrollY)) / boundingRect.height;
    const padding = 0.03;
    xPercent = Math.min(Math.max(xPercent, 0 + padding), 1 - padding);
    yPercent = Math.min(Math.max(yPercent, 0 + padding), 1 - padding);
    const cardsPosI = getCardsPosI(card.word);
    if (cardsPosI !== -1) {
      cardsPosCluegiver.splice(cardsPosI, 1);
    }
    const cardsPosObj = {word: card.word, x: xPercent, y: yPercent};
    cardsPosCluegiver.push(cardsPosObj);
    setCardsPosCluegiver([...cardsPosCluegiver]);
  }

  return (
    <div className="Field">
      <div className="FieldCardsContainer" id="FieldCardsContainer">
        {[...cards].map(card => 
          <div className='FieldCard'
            style={getCardStyle(card)}
            key={card.word}
            data-card-word={card.word}
            onMouseDown={(e) => {if (isCluegiver) {startDrag(e, card)}}}
            onTouchStart={(e) => {if (isCluegiver) {startDrag(e, card)}}}
          >
            {/* Optional to-do: improve code by passing in DOM reference to getCardStyle instead of data-card-word */}
            <Button className='FieldCardButton'
              label={card.word}
              handleClick={(e) => {if (isCluegiver) {cardClick(e, card)}}}
              selected={selectedCards.includes(card)}
              enabled={true}
            />
          </div>
        )}
      </div>
      {!isCluegiver ? null : <div className="FieldActionsContainer">
        <Button
          label={'Recall'}
          handleClick={() => {recallClick()}}
          selected={false}
          enabled={selectedCards.length !== 0}
        />
        {/*<Button
          label={Flip}
          handleClick={() => {flipClick()}}
          selected={false}
          enabled={selectedCards.length !== 0}
        />*/}
      </div>}
    </div>
  );
}

import React from 'react';

import './GameEndOverlay.css';

export const GameEndOverlay = ({roomInfo, resetToMainMenu}) => {
  return (
    <div className="GameEndWrapper">
      <div className="GameEndContent">
        <p>You got it! The word was: {roomInfo.wonword}</p>
        <p><button onClick={resetToMainMenu}>Back to main menu</button></p>
      </div>
    </div>
  );
}

import React from 'react';

import './Button.css';

export const Button = ({label, handleClick, selected=false, enabled=true, highlighted=false, className}) => {
  const classNames = ["Button"];
  if (selected) {
    classNames.push("ButtonSelected");
  }
  if (highlighted) {
    classNames.push("ButtonHighlighted");
  }
  if (className !== undefined) {
    classNames.push(className);
  }
  return (
    <button
      className={classNames.join(' ')}
      disabled={!enabled}
      onClick={handleClick}>
      {label}
    </button>
  );
}

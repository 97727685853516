import React, { useEffect } from 'react';

import './Chat.css';

export const Chat = ({guesses, pendingGuesses, username, target, isCluegiver, submitGuess}) => {

  const formSubmit = (e) => {
    submitGuess(e.target.guess.value);
    e.preventDefault();
    e.target.reset();
  }

  useEffect(() => {
    const lastGuess = document.querySelector(".ChatGuesses > :last-child");
    if (lastGuess !== null) {
      lastGuess.scrollIntoView({behavior: "smooth"});
    }
  }, [guesses, pendingGuesses]);

  const guessElem = (guessUsername, guessStr, i, isPending) => {
    return <div key={i} className={'ChatGuess ' + (isPending ? 'PendingGuess' : 'ServerGuess')}>
      <span className="ChatGuessUsername">{guessUsername}: </span><span className="ChatGuessStr">{guessStr}</span>
    </div>;
  }

  return (
    <div className="Chat">
      <div className="ChatGuesses">
          {guesses.map((guess, i) => guessElem(guess.username, guess.guessStr, i, false))}
          {pendingGuesses.map((guess, i) => guessElem(username, guess.guessStr, i, true))}
        </div>
        {target === null
          ? null
          : <div className="TargetWordContainer"><div className="TargetWordLabel">Answer: </div><div className="TargetWordWord">{target}</div></div>}
        {isCluegiver === true
          ? null
          : <form onSubmit={(e) => {formSubmit(e)}}>
          <label>Guess: <input type="text" name="guess" autoComplete="off" /></label>
          <input type="submit" value="Submit" />
         </form>}
    </div>
  );
}

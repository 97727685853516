import React from 'react';

import './MainMenu.css';

export const MainMenu = ({onClickNewGame, onJoinGame, username}) => {

  const onSubmitJoinGame = (e) => {
    onJoinGame(e.target.code.value, e.target.username.value);
    e.preventDefault();
  }

  const onSubmitNewGame = (e) => {
    onClickNewGame(e.target.username.value);
    e.preventDefault();
  }

  const showTutorial = () => {
    window.alert("The clue-giver places cards to tell the guessers what word they're describing!");
  }

  return (
    <div className="MainMenu">
      <div className="MainMenuTitle">
        <p>Word Game</p>
      </div>
      <div className="MainMenuBox">
        <form onSubmit={(e) => {onSubmitJoinGame(e)}}>
          <label>Join existing game: 
            <div>
              <input 
                type="text"
                name="code"
                required
                autoComplete="off"
                minLength="4"
                maxLength="4"
                size="12"
                placeholder="Room code"
              />
            </div>
          </label>
          <div>
            <input
              type="text"
              name="username"
              placeholder="Your name"
              autoComplete="off"
              maxLength="12"
              size="12"
            />
          </div>
          <input type="submit" value="Join" />
         </form>
      </div>
      <div className="MainMenuBox">
        <form onSubmit={(e) => {onSubmitNewGame(e)}}>
          Start new game as clue-giver:
          <div>
            <input
              type="text"
              name="username"
              placeholder="Your name"
              autoComplete="off"
              maxLength="12"
              size="12"
            />
          </div>
          <input type="submit" value="Create game" />
         </form>
      </div>
      <div className="MainMenuBox">
      Learn how to play:
        <div>
          <button
            className={"HowToPlayButton"}
            onClick={showTutorial}>
            Show instructions
          </button>
        </div>
      </div>
    </div>
  );
}
